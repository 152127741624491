(function () {
    'use strict';

    angular.module('app')
        .controller('FuentesFormController', FuentesFormController);

    /* @ngInject */
    function FuentesFormController($timeout, ModalService, element, FuenteTesauro, tipo, AlertService) {
        var vm = this;

        vm.item = element;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.tipo = tipo;

        vm.save = function () {
            cleanList();
            ModalService.close(vm.item.fuentes);
        };

        vm.clear = function () {
            cleanList();
            ModalService.dismiss('cancel');
        };

        function cleanList() {
            // Eliminamos items vacíos
            if (vm.item.fuentes) {
                vm.item.fuentes = vm.item.fuentes.filter(function (fuente) {
                    return fuente.fuenteTesauro && fuente.fuenteTesauro.id;
                });
            }
        }

        // Lista de fuentes

        function initFuentes() {
            if (!vm.item.fuentes || !vm.item.fuentes.length) {
                vm.item.fuentes = [];
            }
        }

        vm.addFuente = function () {
            vm.item.fuentes.push({});
        };

        vm.removeFuente = function (object) {
            vm.item.fuentes.splice(vm.item.fuentes.indexOf(object), 1);
            initFuentes();
        };

        // Se comprueba que una fuente no ha sido ya añadida a la lista
        vm.comprobarFuentesRepetidas = function (fuenteTesauro, fuente) {
            var error = false;
            angular.forEach(vm.item.fuentes, function (elemento) {
                elemento.error = false;
                if (elemento.fuenteTesauro) {
                    if (elemento.fuenteTesauro.id === fuenteTesauro.id && (fuente !== elemento)) {
                        error = true;
                    }
                }
            });
            if (error) {
                AlertService.error("admin.divisa.form.fuentes.duplicada");
                fuente.error = true;
            }
        };

        initFuentes();

        // Tesauros
        vm.fuenteService = FuenteTesauro;

    }
})();
