(function () {
    'use strict';


    // ini: Fecha de nacimiento.
    // ini-aprox: Fecha de nacimiento aproximada.
    // fin: Fecha de muerte.
    // fin-aprox: Fecha de muerte aproximada.

    var fechaVida = {
        template: '<span ng-if="$ctrl.ini || $ctrl.fin" class="fechaNacimiento">' +
        '(<span ng-if="$ctrl.iniAprox">c.</span><span ng-if="$ctrl.ini">{{$ctrl.ini}}</span><span ng-if="!$ctrl.ini">?</span>-' +
        '<span ng-if="$ctrl.finAprox">c.</span><span ng-if="$ctrl.fin">{{$ctrl.fin}}</span><span ng-if="!$ctrl.fin">?</span>)' +
        '</span>',
        bindings: {
            ini: '@',
            iniAprox: '@',
            fin: '@',
            finAprox: '@'
        }
    };

    angular
        .module('app')
        .component('fechaVida', fechaVida);

})();
