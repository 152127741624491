(function (angular) {
    'use strict';

    angular.module('app', [
        'ui.bootstrap',
        'ui.router',
        'angular-loading-bar',
        'ngAnimate',
        'ngCacheBuster',
        'ngFileUpload',
        'ngResource',
        'ngStorage',
        'ngTagsInput',
        'ngTable',
        'pascalprecht.translate',
        'thatisuday.ng-image-gallery',
        'oc.lazyLoad',
        'ngSanitize'
    ]).config(appConfig).run(appRun);

    /* @ngInject */
    function appRun($rootScope, $translate, $window, $location, $state, $localStorage, Principal, Auth, AlertService, $q) {

        $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
            AlertService.clearPermanent();

            if (toState.redirectTo) {
                event.preventDefault();
                $state.go(toState.redirectTo, toStateParams, {location: 'replace'})
            }

            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;

            if (Principal.isIdentityResolved()) {
                Auth.authorize();
            }
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if ($window.ga) {
                console.log("GoogleAnalytics. Sending: " + $location.path());
                $window.ga('set', 'page', $location.path());
                $window.ga('send', 'pageview');
            }

            var titleKey;

            $rootScope.previousStateName = fromState.name;
            $rootScope.previousStateParams = fromParams;

            // Set the page title key to the one configured in state or use default one
            if (toState.data && toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }
            if (titleKey) {
                $translate(titleKey).then(function (title) {
                    // Cambiar el título de la página por uno traducido
                    $window.document.title = $translate.instant('global.title') + " - " + title;
                });
            }

            $("html, body").scrollTop(0);
        });

        $rootScope.back = function () {
            // If previous state is 'activate' or do not exist go to 'home'
            if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                $state.go('admin/divisa/list');
            } else {
                $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
            }
        };

        $rootScope.changeLanguage = function (key) {
            var deferred = $q.defer();
            if ($translate.use() !== key) {
                $translate.use(key).then(function () {
                    $translate.refresh();
                    deferred.resolve();
                });
                $localStorage.lang = key;
            }
            return deferred.promise;
        };
    }

    /* @ngInject */
    function authInterceptor($localStorage) {
        return {
            // Add authorization token to headers
            request: function (config) {
                config.headers = config.headers || {};
                var token = $localStorage.token;

                if (token && token.expires && token.expires > new Date().getTime()) {
                    config.headers['x-auth-token'] = token.token;
                }

                return config;
            }
        };
    }

    /* @ngInject */
    function appConfig($translateProvider, $provide, $localStorageProvider, DEBUG_INFO_ENABLED, cfpLoadingBarProvider) {
        $translateProvider.useSanitizeValueStrategy('escapeParameters');

        // Configuración del idioma
        $translateProvider.useStaticFilesLoader({
            files: [{
                prefix: 'i18n/',
                suffix: '/componentes.json'
            }, {
                prefix: 'i18n/',
                suffix: '/admin.json'
            }, {
                prefix: 'i18n/',
                suffix: '/public.json'
            }, {
                prefix: 'i18n/',
                suffix: '/enum.json'
            }]
        });

        // i18n
        var lang = $localStorageProvider.get('lang');
        if (!lang || (lang !== 'es' && lang !== 'en')) {
            $translateProvider.preferredLanguage('es');
            $localStorageProvider.set('lang', 'es');
        } else {
            $translateProvider.preferredLanguage(lang);
        }

        // Configuración de la barra de carga
        cfpLoadingBarProvider.latencyThreshold = 150;
        cfpLoadingBarProvider.includeSpinner = false;

        // disable debug data on prod profile to improve performance. Por ahora siempre en modo debug
        console.log("Angular debug:" + DEBUG_INFO_ENABLED);
        // $compileProvider.debugInfoEnabled(DEBUG_INFO_ENABLED);
    }

})(angular);
