(function () {
    'use strict';

    angular.module('app')
        .controller('ContrasenaController', ContrasenaController);

    /* @ngInject */
    function ContrasenaController($timeout, Auth, AlertService) {
        var vm = this;

        vm.form = {};

        $timeout(function () {
            angular.element('[ng-model="registerAccount.login"]').focus();
        });

        vm.save = function () {
            if (vm.form.newpassword !== vm.confirmPassword) {
                AlertService.error("admin.register.error.dontmatch");
            } else {
                Auth.changePassword(vm.form).then(function () {
                    AlertService.success("admin.changepassword.success");
                    vm.form = {};
                    vm.confirmPassword = "";
                    // $state.go("admin/user/list");

                }).catch(function (response) {
                    if (response.status === 200) {
                        AlertService.success("admin.changepassword.success");
                    } else if (response.status === 400 && response.data.msg === 'incorrect password') {
                        AlertService.error("admin.changepassword.error.incorrect");
                    } else {
                        AlertService.error("admin.form.error.fail");
                    }
                });
            }
        };
    }
})();
