(function () {
    'use strict';

    angular.module('app')
        .factory('AcontecimientoTesauro', AcontecimientoTesauro);

    /* @ngInject */
    function AcontecimientoTesauro($resource) {
        return $resource('api/admin/acontecimiento-tesauro/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/public/acontecimiento-tesauro/query'
            },
            'get': {
                method: 'GET',
                url: 'api/public/acontecimiento-tesauro/:id'
            },
            'findAll': {
                method: 'GET',
                isArray: true,
                url: 'api/public/acontecimiento-tesauro/findAll'
            }
        });
    }
})();
