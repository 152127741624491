(function () {
    'use strict';

    angular.module('app')
        .factory('Region', Region);

    /* @ngInject */
    function Region($resource) {
        return $resource('api/admin/region/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/public/region/query'
            },
            'get': {
                method: 'GET',
                url: 'api/public/region/:id'
            },
            'findAll': {
                method: 'GET',
                isArray: true,
                url: 'api/public/region/findAll'
            }
        });
    }
})();
