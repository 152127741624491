(function () {
    'use strict';

    angular.module('app')
        .controller('ImagenesFormController', ImagenesFormController);

    /* @ngInject */
    function ImagenesFormController(ModalService, elements, mode, divisaId) {
        var vm = this;

        vm.elements = angular.copy(elements);
        vm.divisaId = divisaId;
        vm.mode = mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        vm.save = function () {
            angular.forEach(vm.elements, function (imagen) {
                imagen.principal = false;
            });
            if (vm.elements) {
                vm.elements[vm.selected].principal = true;
            }
            cleanList();
            ModalService.close(vm.elements);
        };

        vm.clear = function () {
            cleanList();
            ModalService.dismiss('cancel');
        };

        function cleanList() {
            vm.elements = vm.elements.filter(function (imagen) {
                return imagen.path;
            });
        }

    }
})();
