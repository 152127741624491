(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PropietarioAdminController', PropietarioAdminController);

    /* @ngInject */
    function PropietarioAdminController(Propietario, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS, ModalService, $stateParams, AlertService) {
        var vm = this;

        vm.item = Propietario;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {id: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        var _modalView;
        vm.showRemoveConfirmation = showRemoveConfirmation;
        function showRemoveConfirmation(id) {
            _modalView = ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                keyboard: false,
                    resolve: {
                        item: function () {
                            return Propietario.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.propietario.form.delete.title',
                                body: 'admin.propietario.form.delete.confirm'
                            };
                        }
                    }
                }
            );
            _modalView.result.then(function () {
                AlertService.success("admin.propietario.form.delete.success");
                vm.tableParams.reload();
            });
        }
    }
})
(angular);
