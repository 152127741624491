(function () {
    'use strict';

    // Requiere: label-form
    // Módulos requeridos:
    // "ng-tags-input": "3.0.0"
    // "angular-bootstrap": "1.3.3"

    // id: ID del input.
    // multiple: Boolean. True para usar las etiquetas y false para usar un autcompletado simple.
    // as-list: Boolean. Añadir clase as-list a los elementos para mostrarlos uno debajo de otro.
    // restricted: Boolean. Solo permite añadir elementos que están en el autocompletado.
    // disabled: Boolean. Permite deshabilitar los inputs.
    // minLength: Número de caracteres requeridos para mostrar el autocompletado.
    // key: Clave para el track by
    // display: Propiedad que se va a mostrar (por defecto 'nombre')
    // method: Método del servicio (por defecto 'query')
    // required: Campo obligatorio.
    // resource: Tipo $resource del tesauro. El servicio tiene que implementar la operación query en el controlador.
    //      Ej: IdiomaService. (/idioma/query?query=x)
    // removable: Mostrar o no botón de eliminar
    // info: texto que se muestra a modo tooltip tras el input
    // onChange: Función que se llamada cuando un elemento es seleccionado
    // context: Variable que se envía para poder devolver en el onChange en caso de que el tesauro pertenezca a un objeto

    function Tesauro() {
        var directive = {
            replace: true,
            restrict: 'E',
            controller: 'TesauroController',
            controllerAs: 'ctrl',
            bindToController: true,
            templateUrl: 'app/components/form/tesauro/tesauro.html',
            scope: {
                id: '@',
                label: '@',
                placeholder: '@',
                tooltip: '@',
                resource: '=',
                ngModel: '=',
                multiple: '=',
                asList: '=',
                key: '@',
                display: '@',
                method: '@',
                parameters: '<',
                restricted: '=',
                disabled: '=',
                minLength: '@',
                required: '=',
                removable: '=',
                info: '@',
                onChange: '=',
                context: '='
            }
        };
        return directive;
    }

    angular
        .module('app')
        .directive('tesauro', Tesauro);

})();
