(function () {
    'use strict';

    angular.module('app')
        .controller('DivisaFormController', DivisaFormController);

    /* @ngInject */
    function DivisaFormController($state, $q, itemData, ModalService,
                                  Concepto, FuenteTematica, Autor, LugarOstentacionTesauro, AcontecimientoTesauro,
                                  Propietario, Idioma, FuenteTesauro, RelacionHelper,
                                  AlertService, Color, Motivo, $uibModal, tipos, subtipos, tiposExistencia, tiposFuente, tiposRelacion) {
        var vm = this;

        vm.item = itemData;
        vm.tipos = tipos;
        vm.tiposExistencia = tiposExistencia;
        vm.subtipos = subtipos;
        vm.tiposFuente = tiposFuente;
        vm.tiposRelacion = tiposRelacion;

        if (!vm.item.pictura) {
            vm.item.pictura = {};
        }
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        vm.isopen = false;

        // Guardar formulario
        vm.save = function () {
            if (vm.item.pictura.sinCuerpo) {
                vm.item.pictura.descripcion = "";
                vm.item.pictura.suscriptio = "";
                vm.item.pictura.colores = [];
                vm.item.pictura.motivos = [];
                vm.item.pictura.clasificaciones = [];
            }
            vm.item.$save().then(function (data) {
                switch (vm.mode) {
                    case 'create':
                        AlertService.success("admin.divisa.form.create.success");
                        break;
                    case 'edit':
                        AlertService.success("admin.divisa.form.edit.success");
                        break;
                }
                // TODO: Pasar Page como parámetro para volver a la página del listado en el que se estaba
                $state.go('admin/divisa/list');

            });
        };

        vm.descritoSelected = function () {
            vm.item.pictura.representado = false;
        };

        vm.representadoSelected = function () {
            vm.item.pictura.descrito = false;
        };

        // Propietario
        vm.propietarioService = Propietario;
        if (vm.item.propietario) {
            vm.item.propietario.info = vm.item.propietario.id + " - " + vm.item.propietario.nombre;
        }

        vm.openPropietarioModal = function () {
            ModalService.open({
                templateUrl: 'app/admin/propietario/propietario.form.modal.html',
                controller: 'PropietarioFormController',
                controllerAs: 'formCtrl',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    /* @ngInject */
                    itemData: function (Propietario) {
                        return new Propietario;
                    },
                    mode: function () {
                        return 'create';
                    }
                }
            }).result.then(function (data) {
                vm.item.propietario = data;
            });
        };

        function initList(list) {
            if (!list || !list.length) {
                list = [];
            }
            return list;
        }

        // *** Fuentes de investigación ***

        function initFuentes() {
            if (!vm.item.fuentes || !vm.item.fuentes.length) {
                vm.item.fuentes = [];
            }
        }

        vm.addFuente = function () {
            vm.item.fuentes.push({fuenteTesauro: {}, tipo: 'PRIMARIA'});
        };
        vm.removeFuente = function (element) {
            vm.item.fuentes.splice(vm.item.fuentes.indexOf(element), 1);
            initFuentes();
        };

        // Se comprueba que una fuente no ha sido ya añadida a la lista
        vm.comprobarFuentesRepetidas = function (fuenteTesauro, fuente) {
            var error = false;
            angular.forEach(vm.item.fuentes, function (elemento) {
                elemento.error = false;
                if (elemento.fuenteTesauro) {
                    if (elemento.fuenteTesauro.id === fuenteTesauro.id && (fuente !== elemento)) {
                        error = true;
                    }
                }
            });
            if (error) {
                AlertService.error("admin.divisa.form.fuentes.duplicada");
                fuente.error = true;
            }
        };

        // *** Mote ***

        function initVariantesMote() {
            if (!vm.item.mote) vm.item.mote = {};
            if (!vm.item.mote.variantes || !vm.item.mote.variantes.length) {
                vm.item.mote.variantes = [];
                vm.addVarianteMote();
            }
        }

        vm.addVarianteMote = function () {
            vm.item.mote.variantes.push({codigo: "V" + (vm.item.mote.variantes.length + 1)});
        };
        vm.removeVarianteMote = function (element) {
            vm.item.mote.variantes.splice(vm.item.mote.variantes.indexOf(element), 1);
            initVariantesMote();

            vm.item.mote.variantes.forEach(function (element, index) {
                element.codigo = "V" + (index + 1);

            });
        };

        vm.openFuentesModal = function (variante, tipo) {
            ModalService.open({
                templateUrl: 'app/admin/divisa/fuentes/fuentes.form.modal.html',
                controller: 'FuentesFormController',
                controllerAs: 'formCtrl',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    element: function () {
                        if (variante) {
                            return variante;
                        } else {
                            return vm.item.mote;
                        }
                    },
                    tipo: function () {
                        return tipo;
                    }
                }
            }).result.then(function (data) {
                if (variante) {
                    variante.fuentes = data;
                } else {
                    vm.item.mote.fuentes = data;
                }
            });
        };

        // *** Lugares de ostentación ***

        vm.addLugar = function () {
            vm.item.lugaresOstentacion.push({});
        };
        vm.removeLugar = function (element) {
            vm.item.lugaresOstentacion.splice(vm.item.lugaresOstentacion.indexOf(element), 1);
            vm.item.lugaresOstentacion = initList(vm.item.lugaresOstentacion);
        };

        // *** Acontecimientos ***

        vm.addAcontecimiento = function () {
            vm.item.acontecimientos.push({});
        };
        vm.removeAcontecimiento = function (element) {
            vm.item.acontecimientos.splice(vm.item.acontecimientos.indexOf(element), 1);
            vm.item.acontecimientos = initList(vm.item.acontecimientos);
        };

        // *** Abrir imagen principal a pantalla completa ***

        vm.abrirImagenOriginal = function (path2) {
            $uibModal.open({
                templateUrl: 'app/components/form/imagenes/imagen.modal.html',
                controllerAs: 'ctrl',
                controller: function (path, $uibModalInstance) {
                    var vm = this;
                    vm.clear = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                    vm.path = path;
                },
                size: 'lg',
                resolve: {
                    /* @ngInject */
                    path: function () {
                        return path2.replace("n_", "");
                    }
                }
            });
        };

        // *** Picturas ***

        vm.openPicturaModal = function (imagenes) {
            ModalService.open({
                templateUrl: 'app/admin/divisa/picturas/imagenes.form.modal.html',
                controller: 'ImagenesFormController',
                controllerAs: 'formCtrl',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    elements: function () {
                        return imagenes;
                    },
                    divisaId: function () {
                        return vm.item ? vm.item.id : null;
                    },
                    mode: function () {
                        if (imagenes) {
                            return 'edit';
                        } else {
                            return 'create';
                        }
                    }
                }
            }).result.then(function (data) {
                vm.item.pictura.imagenes = data;
            });
        };

        vm.removePictura = function (element) {
            vm.item.picturas.splice(vm.item.picturas.indexOf(element), 1);
            vm.item.picturas = initList(vm.item.picturas);
        };

        // *** Clasificaciones ***

        function initClasificaciones() {
            if (!vm.item.pictura.clasificaciones) vm.item.pictura.clasificaciones = [];
            if (vm.item.pictura.clasificaciones) {
                vm.item.pictura.clasificaciones.forEach(function (clasificacion) {
                    clasificacion.editable = false;
                });
            }

            if (!vm.item.pictura.clasificaciones || !vm.item.pictura.clasificaciones.length) {
                console.log("No existen clasificaciones");
                vm.item.pictura.clasificaciones = [];
                // vm.addClasificacion();
            }
        }

        vm.addClasificacion = function () {
            vm.item.pictura.clasificaciones.push({
                id: 0,
                nombre: "ICONCLASS",
                iconclassRef: "ICONCLASS",
                iconclassText: "ICONCLASS",
                editable: true
            });
        };
        vm.removeClasificacion = function (element) {
            vm.item.pictura.clasificaciones.splice(vm.item.pictura.clasificaciones.indexOf(element), 1);
            initClasificaciones();
        };

        // Cambiar clasificación asociada a la divisa
        vm.onChange = function (index, element, newelement, isson) {
            vm.findClasificacion(vm.item.pictura.clasificaciones[index], element, newelement, isson);
            element.editable = true;
            vm.item.pictura.clasificaciones[index] = element;

            var defer = $q.defer();
            defer.resolve(true);
            return defer.promise;
        };

        vm.findClasificacion = function (clasificacion, element, newelement, isson) {
            if (isson || clasificacion.iconclassRef === element.iconclassRef) {
                element.iconclassRef = newelement.iconclassRef;
                element.iconclassText = newelement.iconclassText;
                element.nombre = newelement.nombre;
                element.id = newelement.id;

                if (isson) {
                    element.padre = clasificacion;
                } else {
                    element.padre = clasificacion.padre;
                }
                return element;
            }
            if (clasificacion.padre) {
                return vm.findClasificacion(clasificacion.padre, element, newelement);
            }
        };

        initClasificaciones();


        // Relaciones con otras divisas
        function initRelaciones() {
            vm.item.relaciones = initList(vm.item.relaciones);
            // vm.addRelacion();
        }

        vm.addRelacion = function () {
            vm.item.relaciones.push({tipo: 'HEREDA_DE'});
        };
        vm.removeRelacion = function (element) {
            vm.item.relaciones.splice(vm.item.relaciones.indexOf(element), 1);
            initRelaciones();
        };

        // Históricos
        vm.openHistoricosModal = function () {
            ModalService.open({
                templateUrl: 'app/admin/divisa/historicos/historicos.modal.html',
                controller: 'HistoricoController',
                controllerAs: 'ctrl',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    divisaId: function () {
                        return vm.item ? vm.item.id : null;
                    }
                }
            });
        };

        vm.onChangePropietario = function(item, context, model) {
            if (item) {
                model.responsable = item.responsable;
            } else {
                vm.item.propietario.responsable = null;
            }
        }

        // Inicializar listas
        vm.item.lugaresOstentacion = initList(vm.item.lugaresOstentacion);
        vm.item.acontecimientos = initList(vm.item.acontecimientos);
        initFuentes();
        initVariantesMote();
        initRelaciones();

        // Tesauros
        vm.conceptoService = Concepto;
        vm.fuenteTematicaService = FuenteTematica;
        vm.autorService = Autor;
        vm.lugarOstentacionService = LugarOstentacionTesauro;
        vm.acontecimientoService = AcontecimientoTesauro;
        vm.idiomaService = Idioma;
        vm.fuenteService = FuenteTesauro;
        vm.relacionService = RelacionHelper;
        vm.colorService = Color;
        vm.motivoService = Motivo;

    }
})();
