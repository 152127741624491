(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('buscador', {
            parent: 'public',
            url: '/buscador',
            data: {
                pageTitle: 'buscador.avanzada'
            },
            views: {
                'content@': {
                    template: '<buscador-avanzado></buscador-avanzado>'
                }
            }
        }).state('divisa/buscar/avanzada', {
            parent: 'public',
            url: '/divisa/buscar/avanzada',
            data: {
                pageTitle: 'buscador.avanzada'
            },
            views: {
                'content@': {
                    template: '<buscador-avanzado></buscador-avanzado>'
                }
            },
            /* @ngInject */
            onEnter: function($stateParams, $state){
                if ($state.current.name != 'divisa/details') {
                    $stateParams.paramsAnterior = {};
                }
            }
        }).state('divisa/buscar', {
            parent: 'public',
            url: '/divisa/buscar/:query',
            data: {
                pageTitle: 'divisa.list.title'
            },
            views: {
                'content@': {
                    template: '<buscador-resultados divisas-dtos="ctrl.divisasDtos"></buscador-resultados>',
                    /* @ngInject */
                    controller: function (divisasDtos) {
                        this.divisasDtos = divisasDtos;
                    },
                    controllerAs: 'ctrl'
                }
            },
            resolve: {
                /* @ngInject */
                divisasDtos: function (Divisa, $stateParams) {
                    return Divisa.filterSimpleSearch({filterValue: $stateParams.query}).$promise.then(function (data) {
                        return data;
                    });
                }
            }
        });
    }
})();
