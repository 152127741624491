(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/user', {
                parent: 'admin',
                redirectTo: 'admin/user/list',
                url: '/admin/usuarios',
                data: {
                    authorities: ['ROLE_ADMIN']
                }
            })
            .state('admin/user/list', {
                parent: 'admin/user',
                url: '/listar',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'admin.list-users.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/user/list/list-users.html',
                        controller: 'ListUserController',
                        controllerAs: 'listUserCtrl'
                    }
                }
            });
    }
})();
