(function () {
    'use strict';

    angular.module('app')
        .factory('NgTableHelper', NgTableHelper);

    /* @ngInject */
    function NgTableHelper($state) {
        return {
            settings: settings
        };

        function settings(vm) {
            var firstLoad = true;

            if (!vm.function) {
                vm.function = "findAll";
            }

            return {
                defaultSort: 'asc',
                counts: [],
                getData: function ($defer, params) {
                    if (firstLoad) {
                        vm.loading = true;
                    }
                    firstLoad = false;

                    var sortProperty = null;
                    var sortDir = null;
                    if (params.orderBy()[0]) {
                        sortProperty = params.orderBy()[0].replace("-", "").replace("+", "");
                        sortDir = params.sorting()[sortProperty].toUpperCase();
                    }

                    var request = {
                        size: vm.elementosPorPagina,
                        page: params.page() - 1,
                        // sort: sort,
                        sortProperty: sortProperty,
                        sortDirection: sortDir,
                        filterKey: undefined,
                        filterValue: vm.filter.key
                    };

                    angular.extend(request, vm.filter);

                    // Modificamos la URL con el nuevo número de página
                    $state.go('.', {page: params.page()}, {notify: false});

                    return vm.item[vm.function](request).$promise.then(function (data) {
                        params.total(data.totalElements);
                        vm.loading = false;
                        vm.empty = !data.content || data.content.length === 0;
                        return data.content;
                    });
                }
            }
        }
    }
})
();
