(function (angular) {
    'use strict';

    angular.module('app')
        .factory('HomeService', HomeService);

    /* ngInject */
    function HomeService() {
        return {};
    }

})(angular);
