(function () {
    'use strict';

    angular.module('app')
        .factory('Historico', Historico);

    // La ruta para las operaciones de crear, actualizar y eliminar es la de admin.

    /* @ngInject */
    function Historico($resource) {
        return $resource('api/admin/historico/:divisaId', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                url: 'api/public/historico',
                isArray: true
            }
        });
    }
})();
