(function () {
    'use strict';

    angular.module('app')
        .factory('Propietario', Propietario);

    // La ruta para las operaciones de crear, actualizar y eliminar es la de admin.

    /* @ngInject */
    function Propietario($resource) {
        var urlPublica = 'api/public/propietario';

        return $resource('api/admin/propietario/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: urlPublica + '/query'
            },
            'get': {
                method: 'GET',
                url: urlPublica + '/:id'
            },
            'findAll': {
                method: 'GET',
                url: urlPublica + '/findAll'
            },
            'findAllOrderByNombre': {
                method: 'GET',
                url: urlPublica + '/findAllOrderByNombre',
                isArray: true
            }
        });
    }
})();
