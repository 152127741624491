(function () {
    'use strict';

    angular
        .module('app')
        .component('divisaResult', {
            templateUrl: 'app/public/components/divisa/divisa.result.html',
            controllerAs: 'ctrl',
            bindings: {
                divisa: '<'
            }
        });

})();
