(function () {
    'use strict';

    angular.module('app')
        .controller('RegisterController', RegisterController);

    /* @ngInject */
    function RegisterController($translate, $timeout, Auth, RoleService, AlertService) {
        var vm = this;

        vm.registerAccount = {};
        vm.roles = [];
        $timeout(function () {
            angular.element('.form-group:eq(0)>input')[0].focus();
        });

        RoleService.get().$promise.then(function (data) {
            vm.roles = data;
        });

        vm.register = function () {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                AlertService.error("admin.register.error.dontmatch");
            } else {
                vm.registerAccount.langKey = $translate.use();

                Auth.createAccount(vm.registerAccount).then(function () {
                    AlertService.success("admin.register.success");
                    vm.registerAccount = {};
                    vm.confirmPassword = "";
                    // $state.go("admin/user/list");

                }).catch(function (response) {
                    if (response.status === 200) {
                        AlertService.success("admin.register.success");
                    } else if (response.status === 400 && response.data.msg === 'login already in use') {
                        AlertService.error("admin.register.error.alreadyexists");
                    } else {
                        AlertService.error("admin.form.error.fail");
                    }
                });
            }
        };
    }
})();
