(function () {
    'use strict';

    angular.module('app')
        .controller('PropietarioFormController', PropietarioFormController);

    /* @ngInject */
    function PropietarioFormController($timeout, ModalService, itemData, mode, AlertService,
                                       Familia, Pais, Region,
                                       PropietarioSexoService) {
        var vm = this;

        vm.item = itemData;

        vm.mode = mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        vm.elements = vm.item.imagenes;
        vm.isopen = false;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input')[0].focus();
        });

        vm.save = function () {
            angular.forEach(vm.elements, function (imagen) {
                imagen.principal = false;
            });
            if (vm.elements) {
                vm.elements[vm.selected].principal = true;
            }

            cleanList();

            vm.item.imagenes = vm.elements;

            vm.item.$save().then(function (data) {
                switch (vm.mode) {
                    case 'create':
                        AlertService.success("admin.propietario.form.create.success");
                        break;
                    case 'edit':
                        AlertService.success("admin.propietario.form.edit.success");
                        break;
                }
                ModalService.close(data);
            }, function (error) {
                if (error.data.msg === 'instance already exists') {
                    AlertService.warning("admin.propietario.form.create.alreadyexists");
                } else {
                    AlertService.error("admin.form.error.fail");
                }
            });
        };

        vm.clear = function () {
            cleanList();
            ModalService.dismiss('cancel');
        };

        function cleanList() {
            if (typeof(vm.elements) == 'undefined') {
                vm.elements = [];
            }
            vm.elements = vm.elements.filter(function (imagen) {
                return imagen.path;
            });
        }

        // Variante del nombre
        vm.addVariante = function () {
            vm.item.propietarioVariantes.push({});
        };

        vm.removeVariante = function (variante) {
            vm.item.propietarioVariantes.splice(vm.item.propietarioVariantes.indexOf(variante), 1);
            initPropietarioVariantes();
        };

        function initPropietarioVariantes() {
            if (!vm.item.propietarioVariantes || !vm.item.propietarioVariantes.length) {
                vm.item.propietarioVariantes = [{}];
            }
        }

        // Títulos (nobiliarios)
        vm.addTitulo = function () {
            vm.item.propietarioTitulos.push({});
        };

        vm.removeTitulo = function (titulo) {
            vm.item.propietarioTitulos.splice(vm.item.propietarioTitulos.indexOf(titulo), 1);
            initPropietarioTitulos();
        };

        function initPropietarioTitulos() {
            if (!vm.item.propietarioTitulos || !vm.item.propietarioTitulos.length) {
                vm.item.propietarioTitulos = [{}];
            }
        }

        // Inicializar
        initPropietarioVariantes();
        initPropietarioTitulos();

        // Tesauros
        vm.familiaService = Familia;
        vm.paisService = Pais;
        vm.regionService = Region;

        // Enumerados
        PropietarioSexoService.get().$promise.then(function (data) {
            vm.sexos = data;
        });

    }
})();
