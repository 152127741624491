/* globals $ */
(function () {
    'use strict';

    // Módulos requeridos:
    // Tesauro

    // element: Clasificación hoja.
    // leaf: Booleano para saber si es un nodo hoja.
    // onChange: Método del controlador principal (divisa.form.controller.js) que modifica la clasificación hoja.
    // index: Índice de la clasificación en la lista de clasificaciones.
    // editable: Permite desactivar los selects (los inputs para las traducciones están siempre activos)

    angular
        .module('app')
        .directive('clasificacion', Clasificacion);

    function Clasificacion() {
        var directive = {
            replace: true,
            restrict: 'E',
            controller: 'ClasificacionController',
            controllerAs: 'clasificacionCtrl',
            bindToController: true,
            templateUrl: 'app/components/form/clasificacion/clasificacion.html',
            scope: {
                element: '=',
                leaf: '=',
                onChange: '&',
                index: '=',
                editable: '='
            }
        };
        return directive;
    }

})();
