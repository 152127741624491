(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('divisaListAdmin', {
            templateUrl: 'app/admin/divisa/divisa.list.admin.html',
            controller: Controller,
            controllerAs: 'listCtrl',
            bindings: {
                function: '@'
            }
        });

    /* @ngInject */
    function Controller(Divisa, NgTableParams, NgTableHelper, NG_TABLE_DEFAULT_PARAMS, ModalService, $stateParams, AlertService, $translate) {
        var vm = this;

        vm.item = Divisa;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;

        vm.filter = {
            key: undefined,
            supervisada: undefined,
            publicada: undefined,
            propietarioId: $stateParams.propietarioId,
            usuarioId: $stateParams.usuarioId
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {id: 'asc'}
        }, NgTableHelper.settings(vm));

        vm.filtrados = [$translate.instant("divisa.filtrado.TODAS"),
            $translate.instant("divisa.filtrado.SIN_SUPERVISAR"),
            $translate.instant("divisa.filtrado.SIN_PUBLICAR")];

        vm.selectedFiltrado = $translate.instant("divisa.filtrado.TODAS");
        vm.filtradoitemselected = function (filtrado) {
            vm.selectedFiltrado = filtrado;
            if (filtrado === $translate.instant("divisa.filtrado.TODAS")) {
                vm.filter.supervisada = null;
                vm.filter.publicada = null;
            } else if (filtrado === $translate.instant("divisa.filtrado.SIN_PUBLICAR")) {
                vm.filter.supervisada = null;
                vm.filter.publicada = true;
            } else if (filtrado === $translate.instant("divisa.filtrado.SIN_SUPERVISAR")) {
                vm.filter.supervisada = true;
                vm.filter.publicada = null;
            }
        };

        // Eliminar
        var _modalView;
        vm.showRemoveConfirmation = showRemoveConfirmation;

        function showRemoveConfirmation(id) {
            _modalView = ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    keyboard: false,
                    resolve: {
                        item: function () {
                            return Divisa.getAdmin({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.divisa.form.delete.title',
                                body: 'admin.divisa.form.delete.confirm'
                            };
                        }
                    }
                }
            );
            _modalView.result.then(function () {
                AlertService.success("admin.divisa.form.delete.success");
                vm.tableParams.reload();
            });
        }
    }

})(angular);
