(function () {
    'use strict';

    angular.module('app')
        .factory('FuenteTematica', FuenteTematica);

    /* @ngInject */
    function FuenteTematica($resource) {
        return $resource('api/admin/fuente-tematica/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/public/fuente-tematica/query'
            },
            'get': {
                method: 'GET',
                url: 'api/public/fuente-tematica/:id'
            },
            'findAll': {
                method: 'GET',
                isArray: true,
                url: 'api/public/fuente-tematica/findAll'
            }
        });
    }
})();
