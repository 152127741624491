(function () {
    'use strict';

    angular.module('app')
        .factory('Subclase', Subclase);

    /* @ngInject */
    function Subclase($resource) {
        return $resource('api/admin/subclase/:id', {}, {
            'get': {
                method: 'GET',
                url: 'api/public/subclase/:id'
            }
        });
    }
})();