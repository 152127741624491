(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('LoginController', LoginController);

    /* @ngInject */
    function LoginController($rootScope, $state, $timeout, Auth, AlertService, Principal) {
        var vm = this;

        vm.user = {};
        vm.rememberMe = false;

        Principal.identity().then(function (data) {
            if (data) {
                $state.go("admin/divisa/list");
            }
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.login = function () {
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                // En la administración sólo se usa el español
                $rootScope.changeLanguage('es');
                $state.go('admin/divisa/list');

            }).catch(function () {
                AlertService.error("login.form.error.authentication");
            });
        }
    }

})(angular);
