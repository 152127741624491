(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/motivo', {
                parent: 'admin',
                redirectTo: 'admin/motivo/list',
                url: '/admin/motivo',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_INVESTIGADOR']
                }
            })
            .state('admin/motivo/list', {
                parent: 'admin/motivo',
                url: '/listar',
                data: {
                    pageTitle: 'admin.motivo.list.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/motivo/motivo.list.html',
                        controller: 'MotivoController',
                        controllerAs: 'listCtrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    clasesDto: function (Motivo) {
                        return Motivo.findClasesSubclasesMotivos().$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            });
    }
})();
