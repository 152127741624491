(function () {
    'use strict';

    angular.module('app')
        .controller('EntityDeleteModalController', EntityDeleteModalController);

    /* @ngInject */
    function EntityDeleteModalController(item, params, ModalService, AlertService) {
        var vm = this;

        vm.dismiss = ModalService.dismiss;
        vm.item = item;
        vm.params = params;
        vm.confirmRemove = confirmRemove;

        if (!vm.params.property) {
            vm.params.property = 'id';
        }

        function confirmRemove() {
            vm.item.$remove({id: vm.item.id}).then(function () {
                ModalService.close();
            }).catch(function (error) {
                console.log("Modal error: " + error);
                AlertService.error("admin.form.error.delete");
            });
        }
    }
})();
