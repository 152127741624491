(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'public',
            url: '/',
            data: {
                pageTitle: 'global.menu.principal'
            },
            views: {
                'content@': {
                    templateUrl: 'app/public/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'homeCtrl'
                }
            }
        });
    }
})();
