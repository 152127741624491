(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('ClasificacionController', ClasificacionController);

    /* @ngInject */
    function ClasificacionController(IconclassMap, $http) {
        var vm = this;

        vm.loading = false;
        vm.loadingSon = false;
        vm.selected = {};
        vm.elementSon = {};

        vm.onChangeElement = function (element, newelement, isson) {
            vm.onChange({
                index: vm.index,
                element: element,
                newelement: newelement,
                isson: isson
            }).then(function () {
                if (!isson) {
                    vm.loadingSon = true;
                    IconclassMap.loadSelect(vm.element).then(function (data) {
                        vm.iconclassSonItems = data;
                        vm.loadingSon = false;
                    });
                }
            }).catch(function (result) {
                console.log("Error. ", result);
            });
        };

        // Inicializar selects
        function initSelects() {
            if (vm.element && !vm.editable) {
                // No hacemos peticiones contra iconclass y cargamos los valores de BD
                vm.selected = {n: vm.element.iconclassRef, txt: {en: vm.element.iconclassText}};
                vm.iconclassItems = [vm.selected];
            } else {
                // Estamos creando una nueva clasificación
                vm.loading = true;
                IconclassMap.loadSelect(vm.element.padre).then(function (data) {
                    vm.iconclassItems = data;
                    angular.forEach(data, function (element) {
                        if (element.n == vm.element.iconclassRef) {
                            vm.selected = element;
                        }
                    });
                    vm.loading = false;
                });
                vm.loadingSon = true;
                IconclassMap.loadSelect(vm.element).then(function (data) {
                    vm.iconclassSonItems = data;
                    vm.loadingSon = false;
                });
            }
        }

        initSelects();

        vm.selectIconclass = function (element, isson) {
            if (element) {

                $http({method: 'GET', url: 'api/clasificacion/' + element.n}).then(function (result) {
                    vm.newelement = {};
                    vm.newelement.iconclassRef = element.n;
                    vm.newelement.iconclassText = element.txt.en;

                    if (result.data) {
                        vm.newelement.id = result.data.id;
                        vm.newelement.nombre = result.data.nombre;
                    }

                    if (isson) {
                        vm.elementSon.nombre = "";
                        vm.onChangeElement({editable: true}, vm.newelement, isson);
                    } else {
                        vm.element.nombre = "";
                        vm.element.editable = true;
                        vm.onChangeElement(vm.element, vm.newelement, isson);
                    }
                });
            }
        };

        vm.getIconclassName = function (iconclassElement) {
            var text = iconclassElement.txt ? iconclassElement.txt.en : "";
            return iconclassElement.n + " - " + text;
        };

    }
})(angular);
