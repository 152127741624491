(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('equipo', {
                parent: 'public',
                url: '/equipo',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.equipo'
                },
                views: {
                    'content@': {
                        template: '<estatica id="equipo" bd="true" clase="equipo"></estatica>'
                    }
                }
            })
        ;
    }
})();
