(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('site', {
            abstract: true,
            data: {
                authorities: []
            },
            resolve: {
                /* @ngInject */
                authorize: function (Auth) {
                    return Auth.authorize();
                },
                /* @ngInject */
                isFilemanager: function ($rootScope) {
                    $rootScope.isFilemanager = false;
                }
            }
        });
    }
})();
