(function () {
    'use strict';

    angular.module('app')
        .factory('Motivo', Motivo);

    /* @ngInject */
    function Motivo($resource) {
        return $resource('api/admin/motivo/:id', {}, {
            'get': {
                method: 'GET',
                url: 'api/public/motivo/:id'
            },
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/public/motivo/query'
            },
            'findAll': {
                method: 'GET',
                isArray: true,
                url: 'api/public/motivo/findAll'
            },
            'findClasesSubclasesMotivos': {
                method: 'GET',
                isArray: true,
                url: 'api/public/motivo/findClasesSubclasesMotivos'
            },
            'findAllOrderByNombre': {
                method: 'GET',
                url: 'api/public/motivo/findAllOrderByNombre',
                isArray: true
            }
        });
    }
})();
