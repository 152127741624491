(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('divisa', {
                parent: 'public',
                url: '/divisa',
                redirectTo: 'divisa/list',
                data: {
                    authorities: []
                }
            })
            .state('divisa/list', {
                parent: 'divisa',
                url: '/listar',
                data: {
                    pageTitle: 'divisa.list.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/public/divisa/divisa.list.html',
                        controller: 'DivisaController',
                        controllerAs: 'listCtrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    divisas: function (Divisa, LetrasService) {
                        return Divisa.findAllOrderByNombre().$promise.then(function (data) {
                            return LetrasService.getMapaLetras(data, 'mote');
                        });
                    }
                }
            })
            .state('divisa/details', {
                parent: 'divisa',
                url: '/:id',
                data: {
                    pageTitle: 'divisa.list.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/public/divisa/divisa.details.html',
                        controller: 'DivisaDetailsController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    divisa: function (Divisa, $stateParams) {
                        return Divisa.get({id: $stateParams.id}).$promise.then(function (data) {
                            return data;
                        });
                    }
                }
            });
    }
})();
