(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('DivisaController', DivisaController);

    /* @ngInject */
    function DivisaController(divisas) {
        var vm = this;
        vm.divisas = divisas;
    }
})
(angular);
