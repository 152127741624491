(function () {
    'use strict';

    var mote = {
        template: '<span ng-if="!$ctrl.mote">[{{"divisa.sinvalor" | translate}}]</span>' +
        '<span ng-if="$ctrl.mote">{{$ctrl.mote}}</span>',
        bindings: {
            mote: '@'
        }
    };

    angular
        .module('app')
        .component('mote', mote);

})();
