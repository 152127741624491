(function () {
	'use strict';

	angular.module('app')
		.factory('AuthServerProvider', AuthServerProvider);

	/* @ngInject */
	function AuthServerProvider($http, $localStorage) {
		return {
			login: function (credentials) {
				var data = 'username=' + credentials.username + '&password=' + credentials.password;
                return $http.post('api/account/authenticate', data, {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Accept': 'application/json'
					}
				}).success(function (response) {
					$localStorage.token = response;
					return response;
				});
			},
			logout: function () {
				//Stateless API : No server logout
				$localStorage.$reset();
			},
			getToken: function () {
				return $localStorage.token;
			},
			hasValidToken: function () {
				var token = this.getToken();
				return token && token.expires && token.expires > new Date().getTime();
			}
		};
	}

})();
