(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('TesauroController', TesauroController);

    /* @ngInject */
    function TesauroController() {
        var vm = this;

        if (!vm.key) {
            vm.key = "id";
        }

        if (!vm.display) {
            vm.display = "nombre";
        }

        if (!vm.method) {
            vm.method = "query";
        }

        if (!vm.minLength) {
            vm.minLength = 1;
        }

        vm.clear = function () {
            delete(vm.ngModel);
        };

        vm.resetElement = function () {
            delete(vm.ngModel.id);

            if (vm.onChange) {
                vm.onChange(null, vm.context);
            }
        };

        vm.setElement = function (item) {
            if (item.id && !vm.multiple) {
                vm.ngModel.id = item.id;

                if (item.abrev) {
                    vm.ngModel.abrev = item.abrev;
                    vm.ngModel.nombre = item.nombre;
                }
            }
            if (vm.onChange) {
                vm.onChange(item, vm.context, vm.ngModel);
            }
        };

        vm.loadTags = function (query) {
            var params = !vm.parameters ? {} : vm.parameters;
            angular.merge(params, {query: query});
            return vm.resource[vm.method](params).$promise.then(function (data) {
                if (Array.isArray(data)) {
                    return data;
                } else {
                    return data.content;
                }
            });
        };
    }

})(angular);
