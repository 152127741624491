(function () {
    'use strict';

    angular
        .module('app')
        .component('buscadorAvanzado', {
            templateUrl: 'app/public/components/buscador/buscador.avanzado.html',
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller($window, Divisa, Idioma, Motivo, Concepto, EnumService, $stateParams, $state) {
        var vm = this;

        function getIdioma() {
            if (vm.idiomaMote) {
                return vm.idiomaMote.id;
            }
        }

        function init() {
            if ($state.params.paramsAnterior != null && $state.params.paramsAnterior.buscadorAvanzado != null) {
                vm.propietario = $state.params.paramsAnterior.buscadorAvanzado.propietario;
                vm.genero = $state.params.paramsAnterior.buscadorAvanzado.genero;
                vm.familia = $state.params.paramsAnterior.buscadorAvanzado.familia;
                vm.mote = $state.params.paramsAnterior.buscadorAvanzado.mote;
                vm.idiomaMote = $state.params.paramsAnterior.buscadorAvanzado.idiomaMote;
                vm.motivos = $state.params.paramsAnterior.buscadorAvanzado.motivos;
                vm.conceptos = $state.params.paramsAnterior.buscadorAvanzado.conceptos;
                vm.tipoDivisa = $state.params.paramsAnterior.buscadorAvanzado.tipoDivisa;
                vm.subtipoDivisa = $state.params.paramsAnterior.buscadorAvanzado.subtipoDivisa;
                vm.fechaInicio = $state.params.paramsAnterior.buscadorAvanzado.fechaInicio;
                vm.fechaFin = $state.params.paramsAnterior.buscadorAvanzado.fechaFin;
                vm.region = $state.params.paramsAnterior.buscadorAvanzado.region;
                vm.pais = $state.params.paramsAnterior.buscadorAvanzado.pais;
                vm.acontecimiento = $state.params.paramsAnterior.buscadorAvanzado.acontecimiento;
                vm.ocasional = $state.params.paramsAnterior.buscadorAvanzado.ocasional;
                vm.inicioRangoTemporal = $state.params.paramsAnterior.buscadorAvanzado.inicioRangoTemporal;
                vm.finRangoTemporal = $state.params.paramsAnterior.buscadorAvanzado.finRangoTemporal;

                vm.search();
            }
        }

        vm.search = function () {
            var motivos = vm.motivos.map(function (data) {
                return data.id;
            });
            var conceptos = vm.conceptos.map(function (data) {
                return data.id;
            });

            var request = {
                propietario: vm.propietario,
                genero: vm.genero,
                familia: vm.familia,
                mote: vm.mote,
                idiomaMote: getIdioma(),
                motivos: motivos,
                conceptos: conceptos,
                tipoDivisa: vm.tipoDivisa,
                subtipoDivisa: vm.subtipoDivisa,
                fechaInicio: vm.fechaInicio,
                fechaFin: vm.fechaFin,
                region: vm.region,
                pais: vm.pais,
                acontecimiento: vm.acontecimiento,
                ocasional: vm.ocasional,
                inicioRangoTemporal: vm.inicioRangoTemporal,
                finRangoTemporal: vm.finRangoTemporal,

                sortProperty: 'mote',
                sortDirection: 'ASC'
            };

            vm.loading = true;
            Divisa.filterAdvancedSearch(request).$promise.then(function (data) {
                vm.loading = false;
                vm.divisas = data;

                $stateParams.paramsAnterior.buscadorAvanzado = request;
                $stateParams.paramsAnterior.buscadorAvanzado.idiomaMote = vm.idiomaMote;
                $stateParams.paramsAnterior.buscadorAvanzado.motivos = vm.motivos;
                $stateParams.paramsAnterior.buscadorAvanzado.conceptos = vm.conceptos;

                goToResultados();
            });
        };

        function goToResultados() {
            if (!$("#resultados").size()) {
                $window.requestAnimationFrame(goToResultados);
            } else {
                $("body").animate({scrollTop: (angular.element('#resultados').offset().top - 60) + "px"}, 1000);
            }
        }

        // Selects

        if ($state.params.paramsAnterior.buscadorAvanzado == null) {
            vm.conceptos = [];
            vm.motivos = [];
        }
        vm.motivoService = Motivo;
        vm.conceptoService = Concepto;

        Idioma.findAll({sortProperty: 'nombre'}).$promise.then(function (data) {
            vm.idiomas = data;
        });

        EnumService.get("genero").then(function (data) {
            vm.generos = data;
        });

        EnumService.get("tipo").then(function (data) {
            vm.tiposDivisa = data;
        });

        EnumService.get("subtipo").then(function (data) {
            vm.subtiposDivisa = data;
        });
        
        init();

    }

})();
