(function () {
    'use strict';

    angular.module('app')
        .factory('PropietarioSexoService', PropietarioSexoService);

    /* @ngInject */
    function PropietarioSexoService($resource) {
        return $resource('api/public/propietario/sexo', {}, {
            'get': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();
