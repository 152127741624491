(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:textArea
     * @description
     * Componente que añade un editor de texto enriquecido.
     *
     * Referencia: {@link https://docs.ckeditor.com/ckeditor4/docs/}.
     *
     * @param {Object} model Campo que se modificará
     * @param {String} id Identificador del elemento
     * @param {String} name Nombre del elemento. En caso de no indicarse nada, se utilizará el ID
     * @param {String} label Etiqueta
     * @param {Boolean} [disabled=false] Editor deshabilitado
     * @param {Boolean} [required=false] Campo obligatorio
     * @param {String} [tipo=basico] Tipo de editor. Tipos existentes: basico, normal, completo|estatica
     * @param {Number} [height=250] Altura del editor
     * @param {Number} [max=-1] Número máximo de caracteres
     *
     * @requires app.directive:ckEditor
     *
     */
    angular
        .module('app')
        .component('textArea', {
            template: '<label-form key="{{ctrl.label}}" for="{{ctrl.id}}" required="ctrl.required"></label-form>' +
            '<textarea ck-editor id="{{ctrl.id}}" ng-model="ctrl.model" class="{{ctrl.class}}"' +
            ' tipo="{{ctrl.tipo}}" height="{{ctrl.height}}" max="{{ctrl.max}}"' +
            ' ng-required="ctrl.required" ng-disabled="ctrl.disabled"></textarea>',
            bindings: {
                model: '=',
                id: '@',
                name: '@',
                label: '@',
                disabled: '<',
                required: '<',
                tipo: '@',
                height: '@',
                max: '@',
                class: '@'
            },
            controller: Controller,
            controllerAs: 'ctrl'
        });

    /* @ngInject */
    function Controller() {
        var vm = this;

        vm.tipo = vm.tipo || 'basico';
        vm.height = vm.height || 250;
        vm.max = vm.max || -1;
    }

})();
