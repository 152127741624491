(function (angular) {
    'use strict';

    // estatica: Objeto a modificar.
    // idiomas: Idiomas en los que pueden estar las páginas estáticas.

    angular
        .module('app')
        .component('estaticaForm', {
            bindings: {
                estatica: '<',
                idiomas: '<'
            },
            templateUrl: 'app/components/estaticas/admin/estatica-form.admin.html',
            controllerAs: 'ctrl',
            controller: function (Estatica) {
                var vm = this;

                vm.mode = 'edit';
                vm.canSave = true;

                vm.init = function (estatica, index, idioma) {
                    if (!estatica.estaticasI18n[index]) estatica.estaticasI18n[index] = {};

                    if (!estatica.estaticasI18n[index].idioma) {
                        estatica.estaticasI18n[index].idioma = idioma;
                    }
                };

                vm.validar = function () {
                    for (var i = 0; i < vm.idiomas.length; i++) {
                        var idioma = vm.idiomas[i];
                        for (var j = 0; j < vm.estatica.estaticasI18n.length; j++) {
                            var estaticaI18n = vm.estatica.estaticasI18n[j];
                            if (estaticaI18n.idioma === idioma && !estaticaI18n.titulo) {
                                vm.active = i;
                                i = vm.idiomas.length;
                                break;
                            }
                        }
                    }
                };

                vm.save = function () {
                    Estatica.save(vm.estatica);
                };
            }
        });
})(angular);
