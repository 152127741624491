(function (angular) {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:idiomas
     *
     * @description
     * Componente para el cambio de idioma de la aplicación.
     *
     * Permite elegir entre la lista de idiomas existente en el enumerado IdiomaApp.
     *
     */
    angular
        .module('app')
        .component('idiomas', {
            templateUrl: 'app/components/idiomas/idiomas.html',
            /* @ngInject */
            controller: function ($rootScope, $state, $localStorage, EnumService) {
                var vm = this;

                EnumService.get("idioma-app").then(function (data) {
                    vm.idiomas = data;
                });

                vm.changeLanguage = function (lang) {
                    if (lang) {
                        lang = lang.toLowerCase();
                        $rootScope.changeLanguage(lang).then(function () {
                            $state.reload();
                        });
                    }
                    vm.lang = $localStorage.lang;
                };

                // Obtenemos el idioma activo
                vm.changeLanguage();

            },
            controllerAs: 'ctrl'
        });
})(angular);
