(function () {
    'use strict';

    angular.module('app')
        .factory('LetrasService', LetrasService);

    /* @ngInject */
    function LetrasService(Unaccent) {

        return {
            getMapaLetras: getMapaLetras
        };

        // Creación de un mapa cuya clave son las letras del alfabeto y su valor los elementos que comienzan por esa letra.
        // No se tienen en cuenta mayúsculas ni símbolos.
        function getMapaLetras(elementos, atributo) {
            var tmp = {};
            var letter = '';
            for (var i = 0; i < elementos.length; i++) {
                if (elementos[i][atributo] == undefined || elementos[i][atributo] === '') {
                    letter = '-';
                } else {
                    letter = Unaccent.unaccent(elementos[i][atributo].charAt(0).toUpperCase());
                    if (!letter.match(/^[a-zA-Z]+$/)) {
                        letter = Unaccent.unaccent(elementos[i][atributo].charAt(1).toUpperCase());
                    }
                }
                if (tmp[letter] == undefined) {
                    tmp[letter] = [];
                }
                tmp[letter].push(elementos[i]);
            }
            return tmp;
        }

    }

})();
