(function () {
    'use strict';

    angular.module('app')
        .factory('Divisa', Divisa);

    // La ruta para las operaciones de crear, actualizar y eliminar es la de admin.

    /* @ngInject */
    function Divisa($resource, ParseLinks) {
        return $resource('api/admin/divisa/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                url: 'api/public/divisa/:id'
            },
            'getAdmin': {
                method: 'GET',
                url: 'api/admin/divisa/:id'
            },
            'findAll': {
                method: 'GET',
                url: 'api/public/divisa/findAll'
            },
            'findAllOrderByNombre': {
                method: 'GET',
                url: 'api/public/divisa/findAllOrderByNombre',
                isArray: true
            },
            'findAllByUsuario': {
                method: 'GET',
                url: 'api/public/divisa/findAllByUsuario'
            },
            'findAllByMotivo': {
                method: 'GET',
                url: 'api/public/divisa/findAllByMotivo'
            },
            'filterSimpleSearch': {
                method: 'GET',
                url: 'api/public/divisa/filterSimpleSearch'
            },
            'filterAdvancedSearch': {
                method: 'GET',
                url: 'api/public/divisa/filterAdvancedSearch'
            }
        });
    }
})();
