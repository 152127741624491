(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('DivisaDetailsController', DivisaDetailsController);

    /* @ngInject */
    function DivisaDetailsController(divisa, RutasService) {
        var vm = this;
        vm.divisa = divisa;

        // Para citar
        vm.url = RutasService.getBaseUrl() + '/divisa/' + divisa.id;
        vm.fechaActual = new Date();

        // Bibliografía
        vm.hayFuentesPrimarias = false;
        vm.hayFuentesSecundarias = false;
        divisa.fuentes.forEach(function (fuente) {
            if (fuente.tipo === "PRIMARIA") {
                vm.hayFuentesPrimarias = true;
            }
            if (fuente.tipo === "SECUNDARIA") {
                vm.hayFuentesSecundarias = true;
            }
        });

        // Ruta completa imagen
        vm.getRutaCompleta = function (path) {
            return 'upload/picturas/' + divisa.id + '/n_' + path;
        };

        // Gallery
        vm.imagenes = [];
        vm.divisa.pictura.imagenes.forEach(function (imagen) {
            vm.imagenes.push({
                id: imagen.id,
                url: 'upload/picturas/' + vm.divisa.id + '/' + imagen.path
            });
        })
    }
})
(angular);
