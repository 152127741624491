(function () {
    'use strict';

    angular.module('app')
        .factory('FuenteTesauro', FuenteTesauro);

    /* @ngInject */
    function FuenteTesauro($resource) {
        return $resource('api/admin/fuente/:id', {}, {
            'queryInvestigacion': {
                method: 'GET',
                isArray: true,
                url: 'api/public/fuente/queryInvestigacion'
            },
            'queryInspiracion': {
                method: 'GET',
                isArray: true,
                url: 'api/public/fuente/queryInspiracion'
            },
            'get': {
                method: 'GET',
                url: 'api/public/fuente/:id'
            },
            'findAll': {
                method: 'GET',
                isArray: false,
                url: 'api/public/fuente/findAll'
            }
        });
    }
})();
