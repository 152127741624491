(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('propietario', {
            bindings: {
                propietario: '<'
            },
            templateUrl: 'app/components/propietario/propietario.html',
            controller: function () {
                var vm = this;

                // Carousel
                vm.slides = [];
                var currIndex = 0;
                if (vm.propietario) {
                    vm.propietario.imagenes.forEach(function (imagen) {
                        vm.slides.push({
                            image: 'upload/propietarios/' + vm.propietario.id + '/n_' + imagen.path,
                            id: currIndex++
                        });
                    });

                    // Gallery
                    vm.imagenes = [];
                    vm.propietario.imagenes.forEach(function (imagen) {
                        vm.imagenes.push({
                            id: imagen.id,
                            url: 'upload/propietarios/' + vm.propietario.id + '/' + imagen.path,
                            title: imagen.pie
                        });
                    });
                }
            },
            controllerAs: 'ctrl'
        });
})(angular);
