(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('HeaderController', HeaderController);

    /* @ngInject */
    function HeaderController(Language) {
        var vm = this;
        vm.lang = Language.getCurrent();
    }

})(angular);
