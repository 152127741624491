(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('booleanosPictura', {
            bindings: {
                sinCuerpo: '<',
                descrito: '<',
                representado: '<'
            },
            templateUrl: 'app/components/booleanospictura/booleanos-pictura.html',
            controller: function () {
                var vm = this;
            },
            controllerAs: 'ctrl'
        });
})(angular);
