(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('PropietarioController', PropietarioController);

    /* @ngInject */
    function PropietarioController(propietarios, Divisa, LetrasService, $state, $timeout) {
        var vm = this;
        vm.propietarios = propietarios;

        // Obtener las divisas de un propietario
        vm.divisasPropietario = function (propietario) {
            if (!propietario.divisas) {
                Divisa.findAll({
                    propietarioId: propietario.id,
                    supervisada: 'false',
                    publicada: 'false'
                }).$promise.then(function (data) {
                    propietario.divisas = data.content;
                    propietario.isOpen = true;
                })
            } else {
                propietario.isOpen = !propietario.isOpen;
            }
        }

        $timeout(function () {
            if (window.location.href.slice(-2)[0] === '#' && window.location.href.slice(-1)[0].match(/[A-Z]/g).length > 0) {
                document.getElementById(window.location.href.slice(-1)).scrollIntoView();
            }
            if ($state.params.paramsAnterior.propietarioId != null) {
                angular.element('#' + $state.params.paramsAnterior.propietarioId).click();
            }
        });

    }
})
(angular);
