(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/propietario', {
                parent: 'admin',
                redirectTo: 'admin/propietario/list',
                url: '/admin/propietario',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_INVESTIGADOR']
                }
            })
            .state('admin/propietario/list', {
                parent: 'admin/propietario',
                url: '/listar/:page',
                params: {page: {value: '1', dynamic: true}},
                data: {
                    pageTitle: 'admin.propietario.list.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/propietario/propietario.list.html',
                        controller: 'PropietarioAdminController',
                        controllerAs: 'listCtrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    itemData: function (Propietario) {
                        return new Propietario();
                    }
                }
            })
            .state('admin/propietario/create', {
                parent: 'admin/propietario/list',
                url: '/crear',
                data: {
                    pageTitle: 'admin.propietario.form.create.title',
                    mode: 'create'
                },
                onEnter: ['$stateParams', '$state', 'ModalService', function ($stateParams, $state, ModalService) {
                    ModalService.open({
                        templateUrl: 'app/admin/propietario/propietario.form.modal.html',
                        controller: 'PropietarioFormController',
                        controllerAs: 'formCtrl',
                        backdrop: 'static',
                        keyboard: false,
                        size: 'lg',
                        // scope: $scope,
                        resolve: {
                            /* @ngInject */
                            itemData: function (Propietario) {
                                return new Propietario;
                            },
                            mode: function () {
                                return 'create';
                            }
                        }
                    }).result.then(function () {
                        console.log("then");
                        $state.go('admin/propietario/list', null, {reload: true});
                    }, function () {
                        console.log("another");
                        $state.go('admin/propietario/list', null, {reload: true});
                    });
                }]
            })
            .state('admin/propietario/edit', {
                parent: 'admin/propietario/list',
                url: '/editar/:id',
                data: {
                    pageTitle: 'admin.propietario.form.edit.title',
                    mode: 'edit'
                },
                onEnter: ['$stateParams', '$state', 'ModalService', function ($stateParams, $state, ModalService) {
                    ModalService.open({
                        templateUrl: 'app/admin/propietario/propietario.form.modal.html',
                        controller: 'PropietarioFormController',
                        controllerAs: 'formCtrl',
                        backdrop: 'static',
                        keyboard: false,
                        size: 'lg',
                        resolve: {
                            /* @ngInject */
                            itemData: function (Propietario) {
                                return Propietario.get({id: $stateParams.id}).$promise;
                            },
                            mode: function () {
                                return 'edit';
                            }
                        }
                    }).result.then(function () {
                        $state.go('admin/propietario/list', null, {reload: true});
                    }, function () {
                        $state.go('admin/propietario/list');
                    });
                }]
            });
    }
})();
