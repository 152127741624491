/* globals $ */
(function () {
    'use strict';

    angular
        .module('app')
        .directive('radio', radio);

    function radio($compile) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                value: '=',
                label: '@'
            },

            template: '' +
            '<input type="radio" name="name" ng-model="model" ng-value="value" title="{{label | translate}}"' +
            ' ng-checked="model==value" ng-click="model=value">',
            compile: function (tElt) {
                tElt.addClass('sm-radio');
            }
        };
    }

})();
