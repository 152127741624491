(function () {
    'use strict';

    angular.module('app')
        .factory('DivisaTipoRelacionService', DivisaTipoRelacionService);

    /* @ngInject */
    function DivisaTipoRelacionService($resource) {
        return $resource('api/public/divisa/tipo-relacion', {}, {
            'get': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();
