(function (angular) {
    'use strict';

    angular.module('app')
        .service('ModalService', ModalService);

    /* @ngInject */
    function ModalService($uibModal, AlertService) {
        var opened = false;
        var modal = null;

        return {
            opened: function () {
                return opened;
            },
            open: open,
            close: close,
            dismiss: dismiss
        };

        function open(params) {
            if (modal) {
                modal.close();
            }

            opened = true;
            modal = $uibModal.open(params);
            AlertService.clear();
            return modal;
        }

        function close(data) {
            console.log("close");
            opened = false;
            modal.close(data);
        }

        function dismiss(data) {
            console.log("dismiss");
            opened = false;
            modal.dismiss(data);
        }
    }

})(angular);
