(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('buscadorResultados', {
            templateUrl: 'app/public/components/buscador/buscador.resultados.list.html',
            controllerAs: 'ctrl',
            bindings: {
                divisasDtos: '<'
            }
        });

})
(angular);
