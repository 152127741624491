(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('FuenteFormAdminController', FuenteFormAdminController);

    /* @ngInject */
    function FuenteFormAdminController($timeout, $state, itemData, tipo, previousParams, Principal, AlertService) {
        var vm = this;

        vm.tipo = tipo.toLowerCase();

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.item = itemData;
        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        if (vm.mode === 'edit') {
            Principal.hasAuthority("ROLE_ADMIN").then(function (esAdmin) {
                if (!esAdmin) {
                    AlertService.warning("admin.fuente." + vm.tipo + ".error.unauthorized", null, 0);
                }
            });
        }

        // Guardar formulario
        vm.save = function () {
            vm.item.nombre = vm.item.nombre.replace('<br/></p>', '</p>');
            vm.item.tipo = tipo;
            vm.item.$save().then(function () {
                $state.go('admin/fuente/' + vm.tipo + '/list', previousParams);
            });
        };
    }

})(angular);
