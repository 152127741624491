(function (angular) {
    'use strict';

    // elementos: Mapa<Letra, List<Object>>. Mapa cuya clave son las letras del alfabeto y su valor los elementos que comienzan por dicha letra.
    // estado: String. Nombre del estado al que ir.
    // sinvalor: String. Añade un botón a la lista de letras que permite saltar a los elementos que no tienen nombre.
    // en caso de no estar vacío. Se le pasa el nombre de la entidad de acuerdo al fichero de mensajes.

    // Se necesita utilizar la operación LetrasService.getMapaLetras para generar el mapa, ignorando mayúsculas y símbolos.

    angular
        .module('app')
        .component('letras', {
            bindings: {
                elementos: '<',
                estado: '@',
                sinvalor: '@'
            },
            templateUrl: 'app/components/letras/letras.html',
            /* @ngInject */
            controller: function (ALFABETO, $state) {
                var vm = this;
                vm.alfabeto = ALFABETO.toUpperCase();

                // FIXME: Este sistema es bastante ineficiente.
                // Igual sería mejor generar una única vez una lista que tenga la letra y si tiene elementos.
                // Así no se haría el bucle por cada letra del abecedario.
                // Tal vez el Mapa ya debería traer todas las letras. Para eso habría que modificar la operación de LetrasService.getMapaLetras.

                // Función que comprueba si la letra contiene elementos
                vm.letraContenidaElementos = function (letter) {
                    for (var letra in vm.elementos) {
                        if (letter === letra) {
                            return true;
                        }
                    }
                    return false;
                };

                vm.mostrarLetra = function (letra) {
                    $state.go(vm.estado, {'#': letra});
                    if (window.location.href.slice(-2)[0] === '#' && window.location.href.slice(-1)[0] === letra) {
                        document.getElementById(letra).scrollIntoView();
                    }
                }

            },
            controllerAs: 'ctrl'
        });
})(angular);
