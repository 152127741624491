(function () {
    'use strict';

    angular.module('app')
        .factory('Password', Password);

    /* @ngInject */
    function Password($resource) {
        return $resource('api/account/change_password', {}, {})
    }
})();
