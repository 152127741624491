(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('FuenteAdminController', FuenteAdminController);

    /* @ngInject */
    function FuenteAdminController($timeout, $stateParams, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper,
                                   ModalService, FuenteTesauro, tipo) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.tipo = tipo.toLowerCase();

        vm.item = FuenteTesauro;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            tipo: tipo
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {abrev: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                keyboard: false,
                    resolve: {
                        item: function () {
                            return FuenteTesauro.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.fuente.' + vm.tipo + '.delete.title',
                                body: 'admin.fuente.' + vm.tipo + '.delete.confirm',
                                property: 'abrev'
                            };
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        }

    }

})(angular);
