(function () {
    'use strict';

    angular.module('app')
        .factory('User', User);

    /* @ngInject */
    function User($resource, ParseLinks) {
        return $resource('api/admin/user/:login', {login: '@account.login'}, {
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    return {
                        links: ParseLinks.parse(headers('link')),
                        list: angular.fromJson(data)
                    };
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    // Se añade el atributo ID al usuario para poder reutilizar operaciones como eliminar.
                    var result = angular.fromJson(data);
                    result.id = result.login;
                    return result;
                }
            },
            'findAll': {
                method: 'GET',
                url: 'api/admin/user/findAll'
            }
        });
    }
})();
