(function () {
    'use strict';

    // Campos:
    // isLoading: Campo para saber si está oculto o no.
    // class: Clase a mayores de la de por defecto.

    var loading = {
        template: '<img ng-show="$ctrl.isLoading" src="assets/images/loading.svg" class="loading {{$ctrl.class}}"/>',
        bindings: {
            isLoading: '<',
            class: '@'
        }
    };

    angular
        .module('app')
        .component('loading', loading);

})();
