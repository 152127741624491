(function () {
    'use strict';

    angular.module('app')
        .factory('Clase', Clase);

    /* @ngInject */
    function Clase($resource) {
        return $resource('api/admin/clase/:id', {}, {
            'get': {
                method: 'GET',
                url: 'api/public/clase/:id'
            }
        });
    }
})();