(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('MotivoPublicController', MotivoPublicController);

    /* @ngInject */
    function MotivoPublicController(motivos, Divisa, LetrasService, $state, $timeout) {
        var vm = this;
        vm.motivos = motivos;

        // Obtener las divisas de un motivo
        vm.divisasMotivo = function (motivo) {
            if (!motivo.divisas) {
                Divisa.findAllByMotivo({
                    motivoId: motivo.id,
                    supervisada: 'false',
                    publicada: 'false'
                }).$promise.then(function (data) {
                    motivo.divisas = data.content;
                    motivo.isOpen = true;
                })
            } else {
                motivo.isOpen = !motivo.isOpen;
            }
        }

        $timeout(function () {
            if (window.location.href.slice(-2)[0] === '#' && window.location.href.slice(-1)[0].match(/[A-Z]/g).length > 0) {
                document.getElementById(window.location.href.slice(-1)).scrollIntoView();
            }
            if ($state.params.paramsAnterior.motivoId != null) {
                angular.element('#' + $state.params.paramsAnterior.motivoId).click();
            }
        });

    }
})
(angular);
