(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('imagenes', {
            bindings: {
                elements: '=',
                mode: '<',
                divisaId: '<',
                propietarioId: '<',
                selected: '='
            },
            templateUrl: 'app/components/form/imagenes/imagenes.form.html',
            /* @ngInject */
            controller: function ($timeout, ModalService) {
                var vm = this;

                $timeout(function () {
                    angular.element('.form-group:eq(0)>input').focus();
                });

                function cleanList() {
                    if (typeof(vm.elements) == 'undefined') {
                        vm.elements = [];
                    }
                    vm.elements = vm.elements.filter(function (imagen) {
                        return imagen.path;
                    });
                }

                if (vm.divisaId) {
                    vm.rutaCarpetaImagen = "upload/picturas/" + vm.divisaId;
                } else if (vm.propietarioId) {
                    vm.rutaCarpetaImagen = "upload/propietarios/" + vm.propietarioId;
                } else {
                    console.log("No se pasó ni una divisa ni un propietario");
                }

                // *** Imágenes ***

                function initImagenes() {
                    if (!vm.elements || !vm.elements.length) {
                        vm.elements = [];
                    }
                    addEmptyImagen();
                }

                function addEmptyImagen() {
                    vm.elements.push({});
                }

                vm.onUploadedImage = function () {
                    addEmptyImagen();
                };

                vm.onRemoveImage = function (imagen) {
                    if (imagen.principal) {
                        vm.selected = 0;
                    }
                    vm.elements.splice(vm.elements.indexOf(imagen), 1);
                };

                function initPrincipal() {
                    vm.selected = 0;
                    vm.elements.forEach(function (imagen, index) {
                        if (imagen.principal) {
                            vm.selected = index;
                        }
                    })
                }

                cleanList();
                initImagenes();
                initPrincipal();

            },
            controllerAs: 'formCtrl'
        });
})(angular);
