(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/divisa', {
                parent: 'admin',
                redirectTo: 'admin/divisa/list',
                url: '/admin/divisa',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_INVESTIGADOR'],
                    pageTitle: 'admin.divisa.list.title'
                }
            })
            .state('admin/divisa/list', {
                parent: 'admin/divisa',
                url: '/listar/:page',
                params: {page: {value: '1', dynamic: true}},
                views: {
                    'content@': {
                        template: '<divisa-list-admin></divisa-list-admin>'
                    }
                }
            })
            // TODO: Refactorizar? Código igual que en el estado anterior
            .state('admin/divisa/listbypropietario', {
                parent: 'admin/divisa',
                url: '/listar/:page/propietario/:propietarioId',
                params: {page: {value: '1', dynamic: true}},
                views: {
                    'content@': {
                        template: '<divisa-list-admin></divisa-list-admin>'
                    }
                }
            })
            .state('admin/divisa/listbyusuario', {
                parent: 'admin/divisa',
                url: '/listar/:page/usuario/:usuarioId',
                params: {page: {value: '1', dynamic: true}},
                views: {
                    'content@': {
                        template: '<divisa-list-admin function="findAllByUsuario"></divisa-list-admin>'
                    }
                }
            })
            .state('admin/divisa/misdivisas', {
                parent: 'admin/divisa',
                url: '/listar/:page/misdivisas',
                params: {page: {value: '1', dynamic: true}},
                data: {
                    pageTitle: 'admin.divisa.misdivisas.title'
                },
                views: {
                    'content@': {
                        template: '<divisa-list-admin function="findAllByUsuario"></divisa-list-admin>'
                    }
                },
                resolve: {
                    /* @ngInject */
                    principal: function ($stateParams, Principal) {
                        Principal.identity().then(function (data) {
                            $stateParams.usuarioId = data.login;
                        });
                    }
                }
            })
            .state('admin/divisa/create', {
                parent: 'admin/divisa',
                url: '/crear',
                data: {
                    pageTitle: 'admin.divisa.form.create.title',
                    mode: 'create'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/divisa/divisa.form.html',
                        controller: 'DivisaFormController',
                        controllerAs: 'formCtrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    itemData: function (Divisa) {
                        return new Divisa();
                    },
                    /* @ngInject */
                    tipos: function (DivisaTipoService) {
                        return DivisaTipoService.get().$promise;
                    },
                    /* @ngInject */
                    tiposExistencia: function (DivisaTipoExistenciaService) {
                        return DivisaTipoExistenciaService.get().$promise;
                    },
                    /* @ngInject */
                    subtipos: function (DivisaSubtipoService) {
                        return DivisaSubtipoService.get().$promise;
                    },
                    /* @ngInject */
                    tiposFuente: function (FuenteTipoService) {
                        return FuenteTipoService.get().$promise;
                    },
                    /* @ngInject */
                    tiposRelacion: function (DivisaTipoRelacionService) {
                        return DivisaTipoRelacionService.get().$promise;
                    }
                }
            })
            .state('admin/divisa/edit', {
                parent: 'admin/divisa',
                url: '/:id/editar',
                data: {
                    pageTitle: 'admin.divisa.form.edit.title',
                    mode: 'edit'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/divisa/divisa.form.html',
                        controller: 'DivisaFormController',
                        controllerAs: 'formCtrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    itemData: function (Divisa, $stateParams) {
                        return Divisa.getAdmin({id: $stateParams.id}).$promise;
                    },
                    /* @ngInject */
                    tipos: function (DivisaTipoService) {
                        return DivisaTipoService.get().$promise;
                    },
                    /* @ngInject */
                    tiposExistencia: function (DivisaTipoExistenciaService) {
                        return DivisaTipoExistenciaService.get().$promise;
                    },
                    /* @ngInject */
                    subtipos: function (DivisaSubtipoService) {
                        return DivisaSubtipoService.get().$promise;
                    },
                    /* @ngInject */
                    tiposFuente: function (FuenteTipoService) {
                        return FuenteTipoService.get().$promise;
                    },
                    /* @ngInject */
                    tiposRelacion: function (DivisaTipoRelacionService) {
                        return DivisaTipoRelacionService.get().$promise;
                    }
                }

            });
    }
})();
