(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('MotivoController', MotivoController);

    /* @ngInject */
    function MotivoController($timeout, clasesDto, $stateParams, Clase, ModalService, Subclase, Motivo) {
        var vm = this;

        vm.clasesDto = clasesDto;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.editClaseFlag = 0;
        var _modalView;
        var firstTimeClase = true;
        vm.error = false;

        vm.addClase = function () {
            if (firstTimeClase) {
                var claseDto = {};
                claseDto.edicion = true;
                vm.clasesDto.unshift(claseDto);
                $timeout(function () {
                    angular.element('#clase_').focus();
                });
                firstTimeClase = false;
            }
        };

        vm.editClase = function (claseDto) {
            claseDto.edicion = true;
        };

        vm.confirmEditClase = function (claseDto) {
            firstTimeClase = true;
            var clase = {};
            angular.copy(claseDto.clase, clase);
            Clase.save(clase).$promise.then(function (data) {
                claseDto.error = false;
                claseDto.clase.id = data.id;
                claseDto.clase.nombre = data.nombre;
                claseDto.clase.version = data.version;
            }).catch(function () {
                claseDto.edicion = true;
                claseDto.error = true;
                $timeout(function () {
                    angular.element('#clase_').focus();
                });
            });
            claseDto.edicion = false;
        };

        vm.deleteClase = function (claseDto) {
            if (claseDto.edicion) {
                // Entra cuando se está editando una clase
                if (!claseDto.clase || !claseDto.clase.id) {
                    // Entra cuando se añade una clase y se cancela
                    vm.clasesDto.splice(0, 1);
                    firstTimeClase = true;
                } else {
                    // Entra cuando se está editando el nombre de una clase, pero se cancela y se obtiene el que tenía
                    //TODO: Hacer copia del original en vez de peticion al servidor
                    Clase.get({id: claseDto.clase.id}).$promise.then(function (data) {
                        claseDto.clase.nombre = data.nombre;
                        claseDto.edicion = false;
                    });
                }
            } else {
                // Entra cuando se elimina una clase
                _modalView = ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    keyboard: false,
                    resolve: {
                        item: function () {
                            return Clase.get({id: claseDto.clase.id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.clase.form.delete.title',
                                body: 'admin.clase.form.delete.confirm',
                                property: 'nombre'
                            };
                        }
                    }
                });
                _modalView.result.then(function () {
                    vm.clasesDto.splice(vm.clasesDto.indexOf(claseDto), 1);
                });
            }
        };

        vm.desplegarClase = function (claseDto, isopen) {
            claseDto.isopen = !isopen;
        };

        vm.addSubclase = function (clase) {
            var subclase = {};
            subclase.edicion = true;
            if (!clase.subclases) {
                clase.subclases = [];
            }
            clase.subclases.unshift(subclase);
            $timeout(function () {
                angular.element('#subclase_').focus();
            });
        };

        vm.editSubclase = function (claseDto, subclaseDto) {
            subclaseDto.edicion = true;
        };

        vm.confirmEditSubclase = function (claseDto, subclaseDto) {
            subclaseDto.subclase.clase = {};
            subclaseDto.subclase.clase.id = claseDto.clase.id;
            var subclase = {};
            angular.copy(subclaseDto.subclase, subclase);
            Subclase.save(subclase).$promise.then(function (data) {
                subclaseDto.error = false;
                subclaseDto.subclase.id = data.id;
                subclaseDto.subclase.nombre = data.nombre;
                subclaseDto.subclase.clase.id = data.clase.id;
                subclaseDto.subclase.version = data.version;
            }).catch(function () {
                subclaseDto.edicion = true;
                subclaseDto.error = true;
                $timeout(function () {
                    angular.element('#subclase_').focus();
                });
            });
            subclaseDto.edicion = false;
        };

        vm.deleteSubclase = function (claseDto, subclaseDto) {
            if (subclaseDto.edicion) {
                if (!subclaseDto.subclase || !subclaseDto.subclase.id) {
                    claseDto.subclases.splice(0, 1);
                } else {
                    //TODO: Hacer copia del original en vez de peticion al servidor
                    Subclase.get({id: subclaseDto.subclase.id}).$promise.then(function (data) {
                        subclaseDto.subclase.nombre = data.nombre;
                        subclaseDto.edicion = false;
                    });
                }
            } else {
                _modalView = ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    keyboard: false,
                    resolve: {
                        item: function () {
                            return Subclase.get({id: subclaseDto.subclase.id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.subclase.form.delete.title',
                                body: 'admin.subclase.form.delete.confirm',
                                property: 'nombre'
                            };
                        }
                    }
                });
                _modalView.result.then(function () {
                    claseDto.subclases.splice(claseDto.subclases.indexOf(subclaseDto), 1);
                });
            }
        };

        vm.desplegarSubclase = function (subclaseDto, isopen) {
            subclaseDto.isopen = !isopen;
        };

        vm.addMotivo = function (subclaseDto) {
            var motivo = {};
            motivo.edicion = true;
            if (!subclaseDto.motivos) {
                subclaseDto.motivos = [];
            }
            subclaseDto.motivos.unshift(motivo);
            $timeout(function () {
                angular.element('#motivo_').focus();
            });
        };

        vm.editMotivo = function (claseDto, subclaseDto, motivo) {
            motivo.edicion = true;
        };

        vm.confirmEditMotivo = function (claseDto, subclaseDto, motivo) {
            motivo.subclase = {};
            motivo.subclase.id = subclaseDto.subclase.id;
            Motivo.save(motivo).$promise.then(function (data) {
                motivo.error = false;
                motivo.id = data.id;
                motivo.nombre = data.nombre;
                motivo.subclase.id = data.subclase.id;
                motivo.version = data.version;
            }).catch(function () {
                motivo.edicion = true;
                motivo.error = true;
                $timeout(function () {
                    angular.element('#motivo_').focus();
                });
            });
            motivo.edicion = false;
        };

        vm.deleteMotivo = function (subclaseDto, motivo) {
            if (motivo.edicion) {
                if (!motivo.id) {
                    subclaseDto.motivos.splice(0, 1);
                } else {
                    //TODO: Hacer copia del original en vez de peticion al servidor
                    Motivo.get({id: motivo.id}).$promise.then(function (data) {
                        motivo.nombre = data.nombre;
                        motivo.edicion = false;
                    });
                }
            } else {
                _modalView = ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'deleteCtrl',
                    keyboard: false,
                    resolve: {
                        item: function () {
                            return Motivo.get({id: motivo.id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.motivo.form.delete.title',
                                body: 'admin.motivo.form.delete.confirm',
                                property: 'nombre'
                            };
                        }
                    }
                });
                _modalView.result.then(function () {
                    subclaseDto.motivos.splice(subclaseDto.motivos.indexOf(motivo), 1);
                });
            }
        };

        vm.cambioModelo = function (element) {
            if (element.error) {
                element.error = false;
            }
        };

        function plegar(desplegar) {
            for (var i = 0, len = vm.clasesDto.length; i < len; i++) {
                vm.clasesDto[i].isopen = desplegar;
                if (vm.clasesDto[i].subclases) {
                    for (var j = 0, len2 = vm.clasesDto[i].subclases.length; j < len2; j++) {
                        vm.clasesDto[i].subclases[j].isopen = desplegar;
                    }
                }
            }
            vm.replegado = desplegar;
        }

        vm.desplegar = function () {
            plegar(true);
        };

        vm.replegar = function () {
            plegar(false);
        }

    }
})
(angular);
