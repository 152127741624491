(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('propietario', {
                parent: 'public',
                url: '/propietario',
                redirectTo: 'propietario/list',
                data: {
                    authorities: []
                }
            })
            .state('propietario/list', {
                parent: 'propietario',
                url: '/listar',
                data: {
                    pageTitle: 'propietario.list.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/public/propietario/propietario.list.html',
                        controller: 'PropietarioController',
                        controllerAs: 'listCtrl'
                    }
                },
                resolve: {
                    /* @ngInject */
                    propietarios: function (Propietario, LetrasService) {
                        return Propietario.findAllOrderByNombre().$promise.then(function (data) {
                            return LetrasService.getMapaLetras(data, 'info');
                        });
                    }
                }
            });
    }
})();
