(function () {
    'use strict';

    angular.module('app')
        .factory('IconclassMap', IconclassMap);

    // c: Children
    // n: Name
    // txt.en: Texto en inglés

    /* @ngInject */
    function IconclassMap($q, $localStorage, Iconclass) {
        var vm = this;

        vm.loadSelect = loadSelect;

        // Se almacena el map en localStorage

        if (!$localStorage.iconclassMap) {
            $localStorage.iconclassMap = [];
        }

        function loadSelect(element) {
            var deferred = $q.defer();
            if (element && element.iconclassRef) {
                if ($localStorage.iconclassMap[element.iconclassRef]) {
                    loadValues($localStorage.iconclassMap[element.iconclassRef]).then(function (data) {
                        deferred.resolve(data);
                    });
                } else {
                    // console.log("Petición:" + element.iconclassRef);
                    Iconclass.get({id: element.iconclassRef}).$promise.then(function success(result) {
                        $localStorage.iconclassMap[element.iconclassRef] = cleanItem(result.data);
                        loadValues(result.data).then(function (data) {
                            deferred.resolve(data);
                        });
                    }, function error() {
                        // TODO: Qué hacer cuando se produce un 503?
                    });
                }
            }
            return deferred.promise;
        }

        function loadValues(result) {
            var deferred = $q.defer();
            var list = [];
            var parameters = [];
            angular.forEach(result.c, function (value) {
                if ($localStorage.iconclassMap[value]) {
                    list.push($localStorage.iconclassMap[value]);
                } else {
                    parameters.push(value);
                }
            });
            if (parameters.length > 0) {
                // console.log("Petición:" + parameters);
                Iconclass.find({notation: parameters}).$promise.then(function success(result) {
                    angular.forEach(result.data, function (item) {
                        // FIXME: Parece que no se añade bien. Además, se introducen muchos null
                        item = cleanItem(item);
                        $localStorage.iconclassMap[item.n] = item;

                        list.push(item);
                        // if (vm.element.iconclassRef === value.n) {
                        //     vm.selected = value;
                        // }
                    });
                    deferred.resolve(list);
                }, function error() {
                    // TODO: Qué hacer cuando se produce un 503?
                });
            } else {
                deferred.resolve(list);
            }
            return deferred.promise;
        }

        function cleanItem(value) {
            delete(value.kw);
            delete(value.p);
            delete(value.r);
            if (value.txt) {
                delete(value.txt.fr);
                delete(value.txt.cn);
                delete(value.txt.de);
                delete(value.txt.it);
                delete(value.txt.fi);
            }
            return value;
        }

        return vm;
    }
})();
