(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('condiciones', {
                parent: 'public',
                url: '/condiciones',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.condiciones'
                },
                views: {
                    'content@': {
                        template: '<estatica id="condiciones" bd="true"></estatica>'
                    }
                }
            })
        ;
    }
})();
