(function () {
    'use strict';

    angular.module('app')
        .controller('ListUserController', ListUserController);

    /* @ngInject */
    function ListUserController($stateParams, User, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper, ModalService, AlertService) {
        var vm = this;

        vm.item = User;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {login: 'asc'}
        }, NgTableHelper.settings(vm));


        var _modalView;
        vm.showRemoveConfirmation = showRemoveConfirmation;
        function showRemoveConfirmation(login) {
            _modalView = ModalService.open({
                templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                controller: 'EntityDeleteModalController',
                controllerAs: 'deleteCtrl',
                keyboard: false,
                resolve: {
                    item: function () {
                        return User.get({login: login}).$promise;
                    },
                    params: function () {
                        return {
                            title: 'admin.list-users.delete.title',
                            body: 'admin.list-users.delete.confirm'
                        };
                    }
                }
            });
            _modalView.result.then(function () {
                AlertService.success("admin.list-users.delete.success");
                vm.tableParams.reload();
            });
        }
    }
})();
