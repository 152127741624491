(function (angular) {
    'use strict';

    /**
     * @ngdoc directive
     * @name app.directive:ckEditor
     * @description
     * Directiva que incluye un editor de texto CKEditor.
     *
     * __Nota: No utilizar directamente. Usar el componente {@link app.component:textArea}.
     *
     * @requires app.component:textArea
     */
    angular.module('app')
        .directive('ckEditor', directive);

    /* @ngInject */
    function directive($ocLazyLoad, Language, RutasService, $window) {
        console.log("Context:", RutasService.getContext());
        $window.CKEDITOR_BASEPATH = RutasService.getContext() + '/assets/ckeditor/';

        return {
            require: '?ngModel',
            link: function (scope, elm, attr, ngModel) {
                // Ocultamos el textarea mientras no se carga el editor
                elm[0].hidden = true;

                // Cargamos el JS de CKEditor sólo cuando es necesario
                $ocLazyLoad.load({
                    name: 'CKEditor',
                    files: [
                        'assets/ckeditor/ckeditor.js'
                    ]
                }).then(function () {
                    link(scope, elm, attr, ngModel);
                });
            },
            scope: {
                tipo: '@',
                height: '@',
                max: '@',
                class: '@'
            }
        };

        function link(scope, elm, attr, ngModel) {
            // Ponemos el timestamp a null, porque si no añade un parámetro a los CSS y elementos y devuelven un 404.
            CKEDITOR.timestamp = null;

            // Instanciamos el editor
            var ck = CKEDITOR.replace(elm[0], {
                extraPlugins: 'find,justify,wordcount,html5video,html5audio'
            });

            if (!ngModel) return;
            ck.on('instanceReady', function () {
                ck.setData(ngModel.$viewValue);
            });

            function updateModel() {
                scope.$apply(function () {
                    ngModel.$setViewValue(ck.getData());
                });
            }

            ck.on('change', updateModel);
            ck.on('key', updateModel);
            ck.on('dataReady', updateModel);

            ngModel.$render = function () {
                ck.setData(ngModel.$viewValue);
            };

            // Configuración de la Toolbar

            ck.config.toolbar_basico = [
                ['Source'],
                ['Bold', 'Italic', 'Underline'],
                ['Undo', 'Redo'],
                ['Link']
            ];

            ck.config.toolbar_normal = ck.config.toolbar_estatica = [
                ['Source'],
                ['Bold', 'Italic', 'Underline'],
                ['Undo', 'Redo'],
                ['NumberedList', 'BulletedList'],
                ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Outdent', 'Indent'],
                ['Link'],
                ['Maximize']
            ];

            ck.config.toolbar_completo = ck.config.toolbar_estatica = [
                ['Source'],
                ['Bold', 'Italic', 'Underline'],
                ['Undo', 'Redo'],
                ['Cut', 'Copy', 'Find'],
                ['NumberedList', 'BulletedList'],
                ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Outdent', 'Indent'],
                ['Link', 'Image', 'Html5video', 'Html5audio'],
                ['Format', 'RemoveFormat'],
                ['Maximize']
            ];

            // Otras configuraciones

            ck.config.toolbar = scope.tipo;
            ck.config.height = scope.height + 'px';

            ck.config.wordcount = {
                showParagraphs: false,
                showCharCount: true,
                maxCharCount: scope.max
            };

            ck.config.language = Language.getCurrent();

            // TODO: CKFINDER. Añadir a la URL el tipo de documento que se quiere mostrar...? ?type=img,doc
            ck.config.filebrowserBrowseUrl = RutasService.getBaseUrl(true) + '/explorador-archivos';

            ck.config.contentsCss = RutasService.getContext() + '/assets/css/main.css';
            ck.config.bodyClass = scope.class;
        }
    }
})(angular);
