(function () {
    'use strict';

    angular.module('app')
        .factory('Iconclass', Iconclass);

    // Ejemplo: http://iconclass.org/22C.json

    /* @ngInject */
    function Iconclass($resource) {
        return $resource('api/iconclass/:id.json', {}, {
            'get': {
                method: 'GET',
                isArray: false,
                url: 'api/iconclass/:id.json',
                transformResponse: function (data) {
                    return {data: angular.fromJson(angular.fromJson(data))};
                }
            },
            'find': {
                method: 'GET',
                isArray: false,
                url: 'api/iconclass/find',
                params: ':parameters',
                transformResponse: function (data) {
                    return {data: angular.fromJson(angular.fromJson(data))};
                }
            }
        });
    }
})();
