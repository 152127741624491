(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin/estaticas', {
                parent: 'admin',
                url: '/admin/estaticas/:nombre',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'estaticas.title'
                },
                views: {
                    'content@': {
                        template: '<estatica-form estatica="ctrl.estatica" idiomas="ctrl.idiomas"></estatica-form>',
                        controllerAs: 'ctrl',
                        controller: function (estatica, idiomas) {
                            this.estatica = estatica;
                            this.idiomas = idiomas;
                        }
                    }
                },
                resolve: {
                    /* @ngInject */
                    estatica: function (Estatica, $stateParams) {
                        return Estatica.getByNombre({nombre: $stateParams.nombre}).$promise.then(function (data) {
                            return data;
                        });
                    },
                    /* @ngInject */
                    idiomas: function (EnumService) {
                        return EnumService.get('idioma-app');
                    }
                },
                ncyBreadcrumb: {
                    label: 'estaticas.title'
                }
            });
    }
})();
