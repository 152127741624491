(function () {
    'use strict';

    angular.module('app')
        .factory('RelacionHelper', RelacionHelper);

    /* @ngInject */
    function RelacionHelper($resource) {
        return $resource('api/admin/relacion/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/public/divisa/relacion/query'
            },
            'get': {
                method: 'GET',
                url: 'api/public/divisa/relacion/:id'
            }
        });
    }
})();
