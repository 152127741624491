(function (angular) {
    'use strict';

    angular
        .module('app')
        .controller('HomeController', HomeController);

    /* @ngInject */
    function HomeController() {
        var vm = this;
    }

})(angular);
