(function () {
    'use strict';

    angular.module('app')
        .factory('Familia', Familia);

    /* @ngInject */
    function Familia($resource) {
        return $resource('api/admin/familia/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/public/familia/query'
            },
            'get': {
                method: 'GET',
                url: 'api/public/familia/:id'
            },
            'findAll': {
                method: 'GET',
                isArray: true,
                url: 'api/public/familia/findAll'
            }
        });
    }
})();
