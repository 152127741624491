(function () {
    'use strict';

    angular.module('app')
        .factory('Concepto', Concepto);

    /* @ngInject */
    function Concepto($resource) {
        return $resource('api/admin/concepto/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/public/concepto/query'
            },
            'get': {
                method: 'GET',
                url: 'api/public/concepto/:id'
            },
            'findAll': {
                method: 'GET',
                isArray: true,
                url: 'api/public/concepto/findAll'
            }
        });
    }
})();
