(function () {
    'use strict';

    angular.module('app')
        .controller('HistoricoController', HistoricoController);

    /* @ngInject */
    function HistoricoController(ModalService, NgTableParams, divisaId, Historico) {
        var vm = this;

        vm.clear = function () {
            ModalService.dismiss('cancel');
        };

        vm.tableParams = new NgTableParams(
            {}, {
                defaultSort: 'asc',
                counts: [],
                paginate: false,
                getData: function ($defer, params) {
                    vm.loading = true;
                    return Historico.get({divisaId: divisaId}).$promise.then(function (data) {
                        params.total(data.length);
                        params.count(data.length);
                        vm.loading = false;
                        return data;
                    });
                }
            });
    }
})();
